<template>
  <div></div>
</template>

<script>
export default {
  computed: {
    groupId() {
      return this.$route.params.group_id;
    },
  },
  mounted() {
    this.listGroupPlugins();
  },
  methods: {
    async listGroupPlugins() {
      try {
        const params = [
          this.groupId,
          {
            prefix: "guestattributes",
          },
        ];

        const response = await this.$api.groupPlugins.list(...params);
        const responseData = response.data.data || [];

        if (responseData.length > 0) {
          const groupPluginId = responseData[0].id;

          this.$router.push({
            name: "group_user_attributes_edit",
            params: {
              group_id: this.groupId,
              group_plugin_id: groupPluginId,
            },
          });
        } else {
          this.$router.push({
            name: "group_user_attributes_create",
            params: {
              group_id: this.groupId,
            },
          });
        }
      } catch (error) {
        if (error) {
          this.errorMessageShow(error);
        }
      }
    },
  },
};
</script>

<style scoped></style>
